<script setup lang="ts">
import type { SharedOffcanvas } from '~/components/shared/SharedOffcanvas.vue';
import { useSessionContext } from '#imports';
import { provide } from 'vue';
const { sessionContext, languageIdChain } = useSessionContext();
const { isLoggedIn } = useUser();

defineOptions({ name: 'DefaultLayout' });

const { loadNavigationElements } = useNavigation();
const { data } = useLazyAsyncData(
    'mainNavigation',
    () => loadNavigationElements({ depth: sessionContext.value?.salesChannel?.navigationCategoryDepth || 2 }),
    {
        watch: [isLoggedIn, languageIdChain],
    },
);
provide('swNavigation-main-navigation', data);

const { loadNavigationElements: loadFooterNavigationElements } = useNavigation({
    type: 'footer-navigation',
});
const { data: footerData } = useLazyAsyncData(
    'mainFooterNavigation',
    () => loadFooterNavigationElements({ depth: 2 }),
    {
        watch: [isLoggedIn, languageIdChain],
    },
);
provide('swNavigation-footer-navigation', footerData);

const { loadNavigationElements: loadServiceNavigationElements } = useNavigation({
    type: 'service-navigation',
});
const { data: serviceData } = useLazyAsyncData(
    'mainServiceNavigation',
    () => loadServiceNavigationElements({ depth: 2 }),
    {
        watch: [isLoggedIn, languageIdChain],
    },
);
provide('swNavigation-service-navigation', serviceData);

const offcanvas = inject<SharedOffcanvas>('offcanvas') as SharedOffcanvas;
</script>

<template>
    <div class="flex flex-col min-h-dvh">
        <NuxtLoadingIndicator :color="false" />
        <LayoutNotifications />
        <LayoutHeader />

        <main class="flex-1 w-full mx-auto">
            <LayoutLegacyBrowserWarning class="px-5" />
            <slot />
        </main>

        <LayoutFooter />

        <SharedOffcanvasContent
            id="layout-login-offcanvas"
            :offcanvas="offcanvas"
        />
    </div>
</template>
